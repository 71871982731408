import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { useIsMounted } from "@suited/utils";

import AuthenticationTemplate from "suited/components/AuthenticationTemplate/AuthenticationTemplate";

import Loading from "../Loading";
import { useRecaptchaKey } from "./UserRegistration.hooks";
import { IIdentityParams, IUTMParams } from "./UserRegistration.types";
import RegistrationForm from "./components/RegistrationForm";
import { StyledErrorMessage } from "./components/RegistrationForm/RegistrationForm.style";

export interface IUserRegistrationProps {
  industryContext: string;
  autopilotSessionId: string;
  formKey: string;
  invitationCode: string;
  setAuthToken: (token: any) => void;
  utm: IUTMParams;
  identity: IIdentityParams;
}

const UserRegistration = (props: IUserRegistrationProps) => {
  const navigate = useNavigate();
  const { recaptchaKey, loading, error } = useRecaptchaKey();

  const isMounted = useIsMounted();

  const [redirect, setRedirect] = useState(false);

  const handleClickSignIn = () => {
    setRedirect(true);
  };

  if (redirect) {
    if (props.invitationCode) {
      navigate({ to: `/login?code=${props.invitationCode}&utm_source=direct_invitation` });
    } else {
      navigate({ to: "/login" });
    }
  }

  // NOTE: Prevent recaptcha key from rendering before it's fetched
  if (!isMounted || loading) {
    return <Loading />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} useEnterprise>
      <AuthenticationTemplate
        headline="Sign Up for Free"
        ctaText="Sign In"
        ctaOnClick={handleClickSignIn}
        registrationPage
      >
        <RegistrationForm {...props} />
        {/* @ts-ignore */}
        <StyledErrorMessage show={error} error={error} validationFor="server-validation" />
      </AuthenticationTemplate>
    </GoogleReCaptchaProvider>
  );
};

export default UserRegistration;
