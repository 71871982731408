import * as React from "react";
import Skeleton from "react-loading-skeleton";

import { Box, Flex, Stack } from "@suited/components";

import { timeouts } from "suited/components/../constants/interaction-constants";
import { FormResponse } from "suited/components/Forms";
import GeneralDropdown from "suited/components/GeneralSurvey/GeneralDropdown";
import GeneralMultiSelect from "suited/components/GeneralSurvey/GeneralMultiSelect";
import GeneralSingleSelect from "suited/components/GeneralSurvey/GeneralSingleSelect";
import {
  optionsEthnicity,
  optionsGender,
  optionsYesNo
} from "suited/components/GeneralSurvey/GeneralSurveyConstants";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedRadioButton from "suited/components/shared/buttons/SuitedRadioButton";
import SuitedRadioGroup from "suited/components/shared/buttons/SuitedRadioGroup";
import ProgressiveDisclosure from "suited/components/shared/layout/ProgressiveDisclosure";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCopyCaption from "suited/components/shared/typography/SuitedCopyCaption";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

import { ConsentOptionCopy, PersonalDataContent } from "./PersonalDataView.style";
import { validateEthnicity } from "./PersonalDataView.utils";
import { OPTIONS_YEARS } from "./personalDataView.constants";

export interface IPersonalData {
  gender: string;
  ethnicity: string[];
  veteran: string;
  lgbtq: string;
  firstGenCollege: string;
  birthYear: number | null;
}

interface Props {
  personalData: IPersonalData;
  onSave(data): any;
  diversityOptIn?: boolean;
  loading: boolean;
}

interface State {
  formDisabled: boolean;
  showForm: boolean;
  dirty: boolean;
  valid: boolean;
  personalData: IPersonalData;
  showIncompleteFormMessage: boolean;
  showSubmittedFormMessage: boolean;
  focusedDate: boolean;
  diversityOptIn?: boolean;
}

const initialPersonalData = {
  gender: "",
  ethnicity: [],
  veteran: "",
  lgbtq: "",
  firstGenCollege: "",
  birthYear: null
};

const shouldShowForm = (optIn) => {
  if (optIn !== undefined) {
    return optIn;
  }
  return true;
};

const shouldDisableForm = (optIn) => {
  if (optIn !== undefined) {
    return !optIn;
  }
  return true;
};

export default class PersonalDataView extends React.Component<Props, State> {
  state: State = {
    formDisabled: shouldDisableForm(this.props.diversityOptIn),
    showForm: shouldShowForm(this.props.diversityOptIn),
    dirty: false,
    valid: false,
    personalData: this.props.personalData || { ...initialPersonalData },
    showIncompleteFormMessage: false,
    showSubmittedFormMessage: false,
    focusedDate: false,
    diversityOptIn: this.props.diversityOptIn
  };

  componentDidUpdate(prevProps) {
    if (
      !this.state.dirty &&
      JSON.stringify(prevProps.personalData) !== JSON.stringify(this.props.personalData)
    ) {
      this.setState(
        {
          personalData: this.props.personalData || { ...initialPersonalData },
          diversityOptIn: this.props.diversityOptIn,
          showForm: this.props.diversityOptIn || typeof this.props.diversityOptIn === "undefined",
          formDisabled: !this.props.diversityOptIn
        },
        () => {
          this.handleFormValidityChange(this.state.diversityOptIn, this.state.personalData);
        }
      );
    }
  }

  handleDateChange = (year) => {
    this.setState(
      (state) => ({
        dirty: true,
        personalData: {
          ...state.personalData,
          birthYear: year ? Number(year) : null
        }
      }),
      () => {
        this.handleFormValidityChange(this.state.diversityOptIn, this.state.personalData);
      }
    );
  };

  handleChangeFieldValue = (value, name) => {
    this.setState(
      (state) => ({
        dirty: true,
        personalData: {
          ...state.personalData,
          [name]: value
        }
      }),
      () => {
        this.handleFormValidityChange(this.state.diversityOptIn, this.state.personalData);
      }
    );
  };

  handleChangeRaceOrEthnicity = (value: string[], name: string) => {
    const validatedValue = validateEthnicity(value, this.state.personalData.ethnicity);
    this.handleChangeFieldValue(validatedValue, name);
  };

  handleSave = () => {
    this.setState({ dirty: false });
    this.props.onSave({
      ...this.state.personalData,
      diversityOptIn: this.state.diversityOptIn
    });
  };

  handleChangeOptIn = (value: boolean) => {
    this.setState(
      {
        diversityOptIn: value,
        showForm: value,
        formDisabled: !value,
        dirty: true
      },
      () => {
        this.handleFormValidityChange(this.state.diversityOptIn, this.state.personalData);
      }
    );
  };

  handleFormValidityChange = (optIn: boolean | undefined, data: IPersonalData) => {
    this.setState({
      valid:
        optIn === false ||
        (optIn === true &&
          data.ethnicity.length > 0 &&
          data.gender !== "" &&
          data.veteran !== "" &&
          data.lgbtq !== "" &&
          data.firstGenCollege !== "" &&
          data.birthYear !== null)
    });
  };

  render() {
    const { loading } = this.props;
    let completeFormMessage = this.state.showIncompleteFormMessage ? (
      <FormResponse>Please complete all required fields.</FormResponse>
    ) : (
      <FormResponse />
    );

    return (
      <PersonalDataContent>
        <Stack space="xl">
          <SuitedCopyHeadline noMargin>Demographic Information</SuitedCopyHeadline>
          <p>
            If you choose to provide demographic information{" "}
            <strong>(it is entirely optional)</strong>, you understand and agree that it may be used
            for any of the following purposes:
          </p>
          <Box>
            <Stack space="none">
              <SuitedCopySubheadline noMargin>Suited</SuitedCopySubheadline>
              <SuitedCalloutHeadline noMargin>Impartial A.I.</SuitedCalloutHeadline>
              <SuitedCallout noMargin>
                Suited reviews this information in aggregate statistical form to conduct rigorous
                adverse impact analyses in an effort to ensure that its matching algorithms neither
                advantage nor disadvantage members of any demographic group.
              </SuitedCallout>
            </Stack>
          </Box>

          <Box>
            <Stack space="none">
              <SuitedCopySubheadline noMargin>Employers</SuitedCopySubheadline>
              <SuitedCalloutHeadline noMargin>Client Review</SuitedCalloutHeadline>
              <SuitedCallout noMargin>
                Prospective employers with which Suited works may have the option to review this
                demographic information as part of their recruitment processes.
              </SuitedCallout>
            </Stack>
          </Box>

          <SuitedRadioGroup
            name="diversity-opt-in"
            selectedValue={this.state.diversityOptIn}
            onChange={this.handleChangeOptIn}
          >
            <Stack>
              <Box>
                {loading ? (
                  <Flex align="center">
                    <Box style={{ marginRight: "0.6rem" }}>
                      <Skeleton
                        containerTestId="react-loading-skeleton"
                        height={30}
                        width={30}
                        circle
                        inline
                      />
                    </Box>
                    <Box width="100%">
                      <Skeleton height={25} width="100%" inline />
                    </Box>
                  </Flex>
                ) : (
                  <SuitedRadioButton value={true}>
                    I agree to share demographic information
                  </SuitedRadioButton>
                )}
                <ConsentOptionCopy>
                  <Stack space="sm">
                    <SuitedCopyCaption noMargin>
                      {loading ? (
                        <Skeleton style={{ marginTop: "1rem" }} />
                      ) : (
                        <span>
                          I understand that I am <strong>not required</strong> to provide my
                          demographic information.
                        </span>
                      )}
                    </SuitedCopyCaption>
                    <SuitedCopyCaption noMargin>
                      {loading ? (
                        <Skeleton count={2} />
                      ) : (
                        <span>
                          I agree that the demographic information I have voluntarily provided may
                          be used for any of the purposes set forth above.
                        </span>
                      )}
                    </SuitedCopyCaption>
                    <SuitedCopyCaption noMargin>
                      {loading ? (
                        <Skeleton count={2} />
                      ) : (
                        <span>
                          I also understand and agree that{" "}
                          <strong>
                            Suited may provide my demographic information to prospective employers.
                          </strong>
                        </span>
                      )}
                    </SuitedCopyCaption>
                  </Stack>
                </ConsentOptionCopy>
              </Box>
              <Box>
                {loading ? (
                  <Flex align="center">
                    <Box style={{ marginRight: "0.6rem" }}>
                      <Skeleton height={30} width={30} circle inline />
                    </Box>
                    <Box width="100%">
                      <Skeleton height={25} width="100%" inline />
                    </Box>
                  </Flex>
                ) : (
                  <SuitedRadioButton testId="i-do-not-agree" value={false}>
                    <span>I do not agree to share demographic information</span>
                  </SuitedRadioButton>
                )}
                <ConsentOptionCopy>
                  <SuitedCopyCaption noMargin>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <span>
                        I understand that I am <strong>not required</strong> to provide my
                        demographic information.
                      </span>
                    )}
                  </SuitedCopyCaption>
                </ConsentOptionCopy>
              </Box>
            </Stack>
          </SuitedRadioGroup>
          {!loading ? (
            <ProgressiveDisclosure isDisclosed={this.state.showForm}>
              <GeneralDropdown
                disabled={this.state.formDisabled}
                placeholder="Year"
                name="birthYear"
                value={
                  this.state.personalData.birthYear !== null
                    ? {
                        label: String(this.state.personalData.birthYear),
                        value: String(this.state.personalData.birthYear)
                      }
                    : null
                }
                multi={false}
                label="What year were you born?"
                options={OPTIONS_YEARS}
                onChange={this.handleDateChange}
                completed={true}
              />
              <GeneralDropdown
                disabled={this.state.formDisabled}
                placeholder="Select..."
                name="gender"
                value={
                  this.state.personalData.gender !== ""
                    ? {
                        label: this.state.personalData.gender,
                        value: this.state.personalData.gender
                      }
                    : null
                }
                multi={false}
                label="With what gender do you most closely identify?"
                options={optionsGender}
                onChange={this.handleChangeFieldValue}
                completed={true}
              />
              <GeneralMultiSelect
                disabled={this.state.formDisabled}
                name="ethnicity"
                label="What is your racial or ethnic origin? Please select as many as applicable."
                values={this.state.personalData.ethnicity}
                options={optionsEthnicity}
                onChange={this.handleChangeRaceOrEthnicity}
                completed={true}
              />
              <Stack space="lg">
                <GeneralSingleSelect
                  disabled={this.state.formDisabled}
                  name="veteran"
                  label="Are you a current member of the military or a military veteran?"
                  value={this.state.personalData.veteran}
                  options={optionsYesNo}
                  onChange={this.handleChangeFieldValue}
                  completed={true}
                />
                <GeneralSingleSelect
                  disabled={this.state.formDisabled}
                  name="lgbtq"
                  label="Do you identify as a member of the LGBTQ+ community?"
                  value={this.state.personalData.lgbtq}
                  options={optionsYesNo}
                  onChange={this.handleChangeFieldValue}
                  completed={true}
                />
                <GeneralSingleSelect
                  disabled={this.state.formDisabled}
                  name="firstGenCollege"
                  label="Are you a first-generation college student (i.e. your parents did not complete a 4-year college or university degree)?"
                  value={this.state.personalData.firstGenCollege}
                  options={optionsYesNo}
                  onChange={this.handleChangeFieldValue}
                  completed={true}
                />
              </Stack>
            </ProgressiveDisclosure>
          ) : null}

          <Flex justify="flex-end">
            <Box padding="md">
              {completeFormMessage}
              <SuitedButton
                purpose="primary"
                alignRight
                disabled={!this.state.dirty || !this.state.valid}
                onClick={this.handleSave}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Save
              </SuitedButton>
            </Box>
          </Flex>
        </Stack>
      </PersonalDataContent>
    );
  }
}
